\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 구분 -->
          <c-select
            :editable="editable"
            codeGroupCd="SAFETY_EQUIP_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="safetyEquipTypeCd"
            label="LBL0010557"
            v-model="searchParam.safetyEquipTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 안전보호장구/비상방제용품 목록 -->
        <c-table
          ref="localven"
          title="LBL0010558"
          tableId="localven"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          rowKey="psiProtectionEpuipmentId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'washing-safety',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'manageNo' },
          { index: 1, colName: 'manageNo' },
          { index: 2, colName: 'manageNo' },
          { index: 3, colName: 'manageNo' },
          { index: 4, colName: 'manageNo' },
          { index: 5, colName: 'manageNo' },
          { index: 6, colName: 'manageNo' },
          { index: 10, colName: 'manageNo' },
          { index: 11, colName: 'manageNo' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'safetyEquipTypeName',
            field: 'safetyEquipTypeName',
            // 구분
            label: 'LBL0010557',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            // 관리번호
            label: 'LBL0010551',
            style: 'width:120px',
            type: 'link',
            align: 'center',
            sortable: true,
          },
          {
            name: 'areaName',
            field: 'areaName',
            // 지역(공정)
            label: 'LBL0010552',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'itemName',
            field: 'itemName',
            // 품목
            label: 'LBL0010559',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'location',
            field: 'location',
            // 비치장소
            label: 'LBL0010608',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'detailItemName',
            field: 'detailItemName',
            // 세부품목
            label: 'LBL0010560',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'performanceStandard',
            field: 'performanceStandard',
            // 성능 및 규격
            label: 'LBL0010561',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipAmount',
            field: 'equipAmount',
            // 비치수량
            label: 'LBL0010562',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            // 점검주기
            label: 'LBL0010473',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            // 비고
            label: 'LBLREMARK',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        safetyEquipTypeCd: null,
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.protect.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./washingFacilityPlanSafetyDetail.vue"}`);
      this.popupOptions.title = 'LBL0010563'; // 안전보호구/비상방재물품 상세
      this.popupOptions.param = {
        psiProtectionEpuipmentId: result ? result.psiProtectionEpuipmentId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
